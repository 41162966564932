<template>
	<v-flex fill-height style="position:relative">
		<v-layout
			v-show="loading"
			align-center
			class="w-loading-container"
			justify-center
			style="height: 100%; min-height: 200px; position: absolute; width: 100%; z-index: 1"
		>
			<v-progress-circular color="primary" indeterminate />
		</v-layout>
		<v-layout justify-space-between pa-2 row>
			<v-flex headline shrink v-text="catalogTreeStructure.name" />
			<v-flex v-if="catalogTreeStructure.can_sync" shrink>
				<w-btn icon="sync" :loading="synchronizationLoading" small @click="synchronizeTreeStructures">{{ $t('actions.update') }}</w-btn>
			</v-flex>
		</v-layout>
		<v-layout fill-height>
			<v-flex pb-5 scroll-y>
				<v-treeview :items="treeview" hoverable item-children="treeview" item-key="id" item-text="name" :open-all="true" style="max-width: 100%; width: 100%">
					<template v-slot:label="{ item }">
						<v-layout justify-start @click="selectItem(item)">
							<v-flex class="pointer" pr-1>{{ item.name }}</v-flex>
						</v-layout>
					</template>
					<template v-slot:append="{ item }">
						<v-layout align-center justify-end>
							<v-btn color="primary" icon small @click="createSubFolder(item)">
								<v-icon small>add</v-icon>
							</v-btn>
							<v-btn color="primary" :disabled="!item.parent_folder_id" icon small @click="deleteFolder(item)">
								<v-icon small>delete</v-icon>
							</v-btn>
						</v-layout>
					</template>
				</v-treeview>
			</v-flex>
		</v-layout>
		<v-dialog v-model="dialogCreateFolder" max-width="400">
			<v-card>
				<v-card-title :class="$vuetify.breakpoint.mdAndUp ? 'secondary' : 'pl-2 pr-0 py-0 secondary'">
					<v-layout row wrap align-center justify-space-around>
						<v-flex v-t="'sa.geds.create_folder'" grow title white--text></v-flex>
						<v-flex shrink>
							<v-btn class="white--text" icon @click="dialogCreateFolder = false">
								<v-icon>close</v-icon>
							</v-btn>
						</v-flex>
					</v-layout>
				</v-card-title>
				<v-card-text>
					<v-layout v-if="dialogCreateFolder" row wrap>
						<v-flex xs12>
							<v-text-field v-model="newFolder.name" autofocus :label="$t('name')" maxlength="191" @keypress.enter="saveSubFolder"></v-text-field>
						</v-flex>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-layout justify-end>
						<v-btn color="primary" flat @click="saveSubFolder">{{ $t('actions.save') }}</v-btn>
					</v-layout>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-flex>
</template>

<script>
import SuperAdminCatalogTreeStructureModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminCatalogTreeStructureModuleGuard'

export default {
	name: 'TreeStructureContent',
	mixins: [SuperAdminCatalogTreeStructureModuleGuard],
	props: {
		value: {
			default: null,
			required: false,
			type: Number
		}
	},
	data: function () {
		return {
			catalogTreeStructure: {},
			dialogCreateFolder: false,
			loading: false,
			newFolder: {},
			treeview: [],
			subFolder: {},
			synchronizationLoading: false
		}
	},
	watch: {
		value: {
			handler: function (catalogTreeStructureId) {
				if (catalogTreeStructureId) {
					this.loadContext(catalogTreeStructureId)
				}
			}
		}
	},
	mounted: function () {
		if (this.value) {
			this.loadContext(this.value)
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.UPDATE_CATALOG_FOLDER, action: this.updateFolder }
			]
		},
		createSubFolder: function (folder) {
			this.newFolder = {}
			this.subFolder = folder
			this.dialogCreateFolder = true
		},
		deleteFolder: function (folder) {
			this.$dialog
				.warning({
					text: this.$t('actions.are_you_sure'),
					title: this.$t('sa.geds.delete_catalog_folder'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.service.deleteCatalogFolder(this.catalogTreeStructure.id, folder.id).then(() => {
							this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('ecm.folder_deleted'))
							let parentFolder = this.findFolder(folder.parent_folder_id, this.treeview[0])
							if (parentFolder) {
								parentFolder.treeview = parentFolder.treeview.filter(o => o.id != folder.id)
							}
							this.eventBus.emit(this.events.SELECT_CATALOG_FOLDER, null)
						})
					}
				})
		},
		findFolder: function (folderId, node) {
			let result = null
			if (node.id == folderId) {
				return node
			} else if (node.treeview && Array.isArray(node.treeview)) {
				for (const treeviewEntry of node.treeview) {
					const folder = this.findFolder(folderId, treeviewEntry)
					if (folder && folder.id) {
						result = folder
						break
					}
				}
			}
			return result
		},
		getCatalogFolder: function (catalogTreeStructureId) {
			return this.service.getCatalogFolder(catalogTreeStructureId, 'root', {
				depth: 'infinity'
			}).then(root => {
				this.treeview.splice(0, this.treeview.length)
				this.treeview.push(root)
			})
		},
		getIsUpdatable: function (catalogTreeStructureId) {
			return this.service.getCatalogTreeStructure(catalogTreeStructureId)
				.then(catalogTreeStructure => {
					this.catalogTreeStructure = catalogTreeStructure
				})
		},
		loadContext: function (catalogTreeStructureId) {
			this.loading = true
			const promises = [
				this.getIsUpdatable(catalogTreeStructureId),
				this.getCatalogFolder(catalogTreeStructureId)
			]

			return Promise.all(promises)
				.finally(() => {
					this.loading = false
				})
		},
		saveSubFolder: function () {
			this.newFolder.catalog_folder_id = this.subFolder.id
			this.service.createCatalogFolder(this.catalogTreeStructure.id, this.newFolder).then(res => {
				if (!this.subFolder.treeview || !Array.isArray(this.subFolder.treeview)) {
					this.subFolder.treeview = []
				}
				res.treeview = []
				this.subFolder.treeview.push(res)
				this.dialogCreateFolder = false
				this.selectItem(res)
			})
		},
		selectItem: function (item) {
			if (item.parent_folder_id) {
				this.eventBus.emit(this.events.SELECT_CATALOG_FOLDER, item.id)
			}
		},
		synchronizeTreeStructures: function () {
			this.$dialog
				.warning({
					text: this.$t('sa.geds.actions.refresh.are_you_sure'),
					title: this.$t('sa.geds.actions.refresh.title'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.synchronizationLoading = true
						this.service.synchronizeTreeStructures(this.catalogTreeStructure.id).then(() => {
							this.catalogTreeStructure.can_sync = false
						}).finally(() => {
							this.synchronizationLoading = false

						})
					}
				})
		},
		updateFolder: function (item) {
			let folder = this.findFolder(item.id, this.treeview[0])
			if (folder) {
				folder.name = item.name
			}
		}
	}
}
</script>
